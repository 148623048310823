<template>
	<div class="category-preview-box">
		<p
			v-if="ableToSetPrimary"
			class="category-primary-label typo-caption color-black-45"
		>
			Primary category
		</p>
		<ul class="category-list">
			<li
				v-if="primaryCategory"
				class="category-item category-primary-item typo-body-2"
			>
				<span
					v-for="(path, index) in primaryCategory.pathName"
					:key="`category-primary-label-name-${index}`"
					class="category-name"
				>
					{{ path }}
				</span>
			</li>
			<li
				v-for="category in categoryList"
				:key="category.id"
				:class="[
					'category-item',
					'typo-body-2',
					{ 'able-to-set-primary': ableToSetPrimary }
				]"
				@click="handleOpenModalConfirmSelectPrimary(category.id)"
			>
				<span
					v-for="(path, index) in category.pathName"
					:key="`category-label-name-${index}`"
					class="category-name"
				>
					{{ path }}
				</span>
			</li>
		</ul>

		<BaseModalConfirm
			ref="modal-confirm"
			title="Set primary category?"
			description="Select and set this category as a primary category for the product."
			@onConfirm="handleSelectPrimaryCategory"
			@onClose="handleClosedModalConfirmSetPrimary"
		/>
	</div>
</template>

<script>
import BaseModalConfirm from '@/components/BaseModalConfirm.vue';

export default {
	name: 'PreviewBoxCategory',
	components: {
		BaseModalConfirm,
	},
	props: {
		categories: {
			type: Array,
			default: () => [],
		},
		primaryId: {
			type: Number,
			default: null,
		},
		ableToSetPrimary: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			confirmingCategoryId: null,
		};
	},
	computed: {
		categoryList() {
			if (this.ableToSetPrimary) {
				return this.categories.filter((item) => item.id !== this.primaryId);
			}
			return this.categories;
		},
		primaryCategory() {
			if (this.ableToSetPrimary) {
				return this.categories.find((item) => item.id === this.primaryId) || null;
			}
			return null;
		},
	},
	methods: {
		handleOpenModalConfirmSelectPrimary(categoryId) {
			this.confirmingCategoryId = categoryId;
			this.$refs['modal-confirm'].open();
		},
		handleClosedModalConfirmSetPrimary() {
			this.confirmingCategoryId = null;
		},
		handleSelectPrimaryCategory() {
			this.$emit('onSelectPrimaryCategory', this.confirmingCategoryId);
		},
	},
};
</script>

<style lang="scss" scoped>
	.category-preview-box {
		overflow-y: auto;
		min-height: rem(60);
		max-height: rem(468);
		padding: rem(20) rem(16);
		border-radius: rem(4);
		border: solid 1px $color-gray-400;
	}

	.category-list {
		padding: 0;
		margin: 0 0 rem(-12) 0;
		list-style: none;
	}

	.category-item {
		border-radius: rem(4);
		background-color: $color-gray-300;
		color: $color-black-90;
		display: inline-block;
		padding: 0 rem(6);
		margin-right: rem(12);
		margin-bottom: rem(12);

		&.able-to-set-primary {
			transition: background-color $transition-duration;
			cursor: pointer;

			&:hover {
				background-color: $color-gray-400;
			}
		}

		.category-name {
			color: $color-black-45;

			&::after {
				content: "/";
				display: inline-block;
			}

			&:last-child {
				color: $color-black-90;
				font-weight: 600;

				&::after {
					display: none;
				}
			}
		}
	}

	.category-primary-item {
		background-color: $color-primary-25;
	}

	.category-primary-label {
		margin-bottom: rem(4);
	}
</style>
